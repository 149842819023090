import ConfiguratorLayout from '@components/ConfiguratorLayout';
import Controls from '@components/Controls';
import PortableText from '@components/PortableText';
import ProductCode from '@components/ProductCode';
import SkuContext from '@components/SkuContext';
import NavbarContext from '@src/context/NavbarContextOld';
import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { CONFIGURATOR_BUTTON_STYLE } from '../../../common';
import FinishButton from '../FinishButton';
import ProductImagesCarousel from '../ProductImagesCarousel';
import * as styles from './styles';
import useCustomFinishCheck from '@src/utility/useCustomRegexHook';
import { getMarkingSuffix } from '@src/helpers/product';

const Configurator = ({
  options,
  lang,
  productOptionList,
  customPrice,
  configurationReminder,
  slug,
  image,
  title,
  children,
  modalState,
  scrollToState,
  productCode,
  blankProductCode,
  hasOffImage,
  productImagesCarousel,
  productFinishes,
  markingData,
  showOnPDP
}) => {
  const [showImageSpinner, setShowImageSpinner] = useState(true);
  const [imageMarking, setImageMarking] = useState('');
  const { checkCustomFinish } = useCustomFinishCheck();

  const updateUrl = useContext(SkuContext);
  const { isVisible: isNavbarVisible } = useContext(NavbarContext);
  let productOptionNamesToBeReplaced = [];
  let tabularData = [];
  let newHeader = '';

  const needsReplacement = () => {
    return productOptionNamesToBeReplaced?.some((n) =>
      productOptionList.includes(n),
    );
  };

  useEffect(() => {
    if (showOnPDP && markingData) {
      setImageMarking('?'+getMarkingSuffix(markingData));
    }
  }, [])

  useEffect(() => {
    // console.log(showImageSpinner);
  }, [showImageSpinner]);

  const getAriaLabelName = (buttonState, buttonName) => {
    if (buttonState === 'disabled') {
      return `This configuration is not available in ${buttonName}`
    } else if (buttonState === 'selected') {
      return `Current Selection: ${buttonName}`
    } else if (buttonState === 'enabled') {
      return `Choose ${buttonName}`
    }

    return `Choose ${buttonName}`;
  }

  const isCustom = (val) => {
    return checkCustomFinish(val);
  }

  return (
    <StaticQuery
      query={graphql`
        query sanityConfiguratorColors {
          sanityConfigurator {
            colorTempSwatches {
              color {
                hex
              }
              code
            }
          }
          sanityTable {
            newHeader
            productOptionNames
            tabularData
          }
        }
      `}
      render={(data) => {
        const swatches = data.sanityConfigurator?.colorTempSwatches;
        const cctColorOption = swatches.reduce((accum, current) => {
          const obj = accum;
          obj[current.code] = current.color.hex;
          return obj;
        }, {});

        tabularData = JSON.parse(data.sanityTable?.tabularData);
        newHeader = data.sanityTable?.newHeader ?? '';
        productOptionNamesToBeReplaced = JSON.parse(
          data.sanityTable?.productOptionNames,
        );

        const Option = ({
          optionData,
          optionIndex,
          printOnly,
          cct,
          hideInPrint,
        }) => {
          return Object.entries(optionData).map((el) => {
            const disabled = el[1] === 0 && !cct;

            return (
              <li
                key={el[0]}
                className={cn(
                  { 'hidden print:block': printOnly || disabled },
                  { 'print:hidden': hideInPrint },
                  'print:type-sans-042 print:!leading-normal'
                )}
              >
                <button
                  className={cn(
                    `relative btn btn-config btn-config--${
                      CONFIGURATOR_BUTTON_STYLE[el[1]]
                    }`,
                    {
                      'btn-config--cct': cct,
                    },
                    'print:type-sans-042 print:!leading-normal print:px-2'
                  )}
                  aria-label={getAriaLabelName(CONFIGURATOR_BUTTON_STYLE[el[1]], lang[el[0]])} 
                  disabled={el[1] !== 1}
                  type="button"
                  onClick={() => {
                    updateUrl(el[0], optionIndex);
                  }}
                >
                  {cct && (
                    <div
                      className="w-6 h-6 rounded-full mr-4 border border-mono-400 print:hidden"
                      style={{ backgroundColor: cctColorOption[el[0]] }}
                    />
                  )}
                  <span className="px-2 print:px-0 print:type-sans-042">{lang[el[0]]} </span>
                  <span
                    className="stonly-trigger-container"
                    onClick={(e) => e.preventDefault()}
                  ></span>
                  <span className="hidden print:block absolute right-2 top-0 px-0 type-sans-042">
                    {isCustom(el[0]) && CONFIGURATOR_BUTTON_STYLE[el[1]] === 'selected' && <span className='mr-2'>Contact sales for custom finish</span>}
                    {el[0]}
                  </span>
                </button>
              </li>
            );
          });
        };

        const StaticOption = () => {
          return tabularData.map((el, index) => {
            return (
              <li
                key={'dci-' + index}
                className={cn(
                  'hidden print:block print:type-sans-042 print:!leading-normal',
                  { 'pb-2': index === tabularData?.length - 1 },
                  { '-mt-2': index === 0 },
                )}
              >
                <>
                  {el[0] && el[2] && (
                    <button aria-label={getAriaLabelName('', el[0])} className="relative btn btn-config print:px-2 print:type-sans-042 print:!leading-normal" type="button">
                      <span>{el[0]}</span>
                      <span
                        className="absolute top-0 right-align px-2 print:px-0"
                        style={{ right: '150px' }}
                      >
                        {el[1]}
                      </span>
                      <span className="absolute right-2 top-0 px-2 print:px-0 right-align">
                        {el[2]}
                      </span>
                    </button>
                  )}
                  {!(el[0] || el[1] || el[2]) && (
                    <div style={{ height: '6px', width: '100%' }}></div>
                  )}
                </>
              </li>
            );
          });
        };

        return (
          <ConfiguratorLayout
            imageChildren={
              <div className={`w-full ${styles.configImage} top-correction`}>
                <div className={styles.configImageInner}>
                  <div
                    className={cn(styles.configSpinner, {
                      '!hidden': !showImageSpinner,
                    })}
                  />
                  {!hasOffImage && productImagesCarousel?.length <= 0 ? (
                    <>
                      <img
                        className={cn('object-contain h-full w-full relative')}
                        src={image + imageMarking}
                        alt={title}
                        height="auto"
                        width="100%"
                        onLoad={() => {
                          setShowImageSpinner(false);
                        }}
                      />
                      {customPrice && (
                        <div className={`${styles.customWrapper} print:hidden`}>
                          <span>Contact sales for custom finish</span>
                        </div>
                      )}
                      {configurationReminder && (
                        <div className={styles.configurationReminderWrapper}>
                          <PortableText
                            className={styles.configurationReminder}
                            content={configurationReminder}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <ProductImagesCarousel
                      images={[
                        ...(hasOffImage
                          ? [image.replace(/.jpg$/, '_ON.jpg') + imageMarking]
                          : [image]),
                        ...(hasOffImage
                          ? [image.replace(/.jpg$/, '_OFF.jpg') + imageMarking]
                          : []),
                        ...productImagesCarousel,
                      ]}
                      alt={title}
                      onLoad={() => {
                        setShowImageSpinner(false);
                      }}
                      configuratorTextOverlapIndex={hasOffImage ? 2 : 1}
                      customPrice={customPrice}
                      configurationReminder={configurationReminder}
                    />
                  )}
                </div>
              </div>
            }
          >
            {(hasOffImage || productImagesCarousel?.length > 0) && configurationReminder && (
              <div className={`${styles.configurationReminderWrapper} !relative md:hidden mt-2 mb-6 !p-0 print:hidden`}>
                <PortableText
                  className={styles.configurationReminder}
                  content={configurationReminder}
                />
              </div>
            )}
            <div
              className={cn(
                styles.configTop,
                {
                  '!top-0': !isNavbarVisible,
                  '!delay-100': isNavbarVisible,
                  '!delay-[-100ms]': !isNavbarVisible,
                },
                'top-correction',
                'print:hidden'
              )}
            >
              <ProductCode
                productCode={productCode}
                blankProductCode={blankProductCode}
                sticky
              />
              <Controls />
              <hr className="border-mono-300 mt-4 mb-6 print:border-mono-500" />
            </div>

            {/* <h4 className="hidden print:block mb-4">Specification Logic</h4> */}
            <ul>
              {options && options.map((el, index) => {
                if (Object.keys(el).length >= 1) {
                  return (
                    <li key={index} className={`mb-10 print:mb-2 ${styles.configItem}`}>
                      <label
                        aria-label={`Options: ${productOptionList[index]}`}
                        className={cn(
                          {
                            'type-upper-140 mb-4 print:type-upper-072 print:mb-0 block':
                              productOptionList[index] &&
                              productOptionNamesToBeReplaced.includes(
                                productOptionList[index],
                              ),
                          },
                          {
                            'type-upper-140 mb-4 print:type-upper-072 print:mb-0 block':
                              !(
                                productOptionList[index] &&
                                productOptionNamesToBeReplaced.includes(
                                  productOptionList[index],
                                )
                              ),
                          },
                        )}
                      >
                        {/* display feedback to rbw team via gatsby build logs if option list does not contain label */}
                        {typeof window === `undefined` &&
                        typeof productOptionList[index] === `undefined`
                          ? console.log(
                              `${slug}: option ${
                                index + 1
                              } does not have label`,
                            )
                          : null}

                        {productOptionList[index] ? (
                          productOptionNamesToBeReplaced.includes(
                            productOptionList[index],
                          ) ? (
                            <span className="print:hidden">
                              {productOptionList[index]}
                            </span>
                          ) : (
                            productOptionList[index]
                          )
                        ) : (
                          `Option ${index}`
                        )}
                        {}
                        <span className="stonly-trigger-container">
                          <span className="stonly-trigger-neighbor"></span>
                        </span>
                      </label>
                      <ul>
                        {(() => {
                          if (!productOptionList[index]) {
                            return (
                              <Option optionData={el} optionIndex={index} />
                            );
                          }
                          if (productOptionList[index].includes('Finish')) {
                            return (
                              <>
                                <FinishButton
                                  possibleFinishes={el}
                                  optionIndex={index}
                                  lang={lang}
                                  optionName={productOptionList[index]}
                                  modalState={modalState}
                                  scrollToState={scrollToState}
                                  productFinishes={productFinishes}
                                />
                                <Option
                                  optionData={el}
                                  optionIndex={index}
                                  printOnly
                                />
                              </>
                            );
                          }
                          if (
                            productOptionList[index].includes('CCT') ||
                            productOptionList[index].includes(
                              'Color Temperature',
                            ) ||
                            productOptionList[index].includes('Temperature')
                          ) {
                            return (
                              <Option optionData={el} optionIndex={index} cct />
                            );
                          }
                          return (
                            <Option
                              optionData={el}
                              optionIndex={index}
                              hideInPrint={productOptionNamesToBeReplaced.includes(
                                productOptionList[index],
                              )}
                            />
                          );
                        })()}
                      </ul>
                    </li>
                  );
                }
                return null;
              })}

              {needsReplacement() && (
                <li key={'hidden-one'} className={`mb-10 ${styles.configItem}`}>
                  <h4 className="type-upper-140 mb-4 print:type-upper-072 print:border-b print:pb-2">
                    <span className="hidden print:block">{newHeader}</span>
                  </h4>
                  <ul>
                    <StaticOption />
                  </ul>
                </li>
              )}
            </ul>
            {children}
            <div className={`${styles.dateStamp} type-sans-040 !text-[0.65rem] z-20 fixed bottom-0 right-0`}>
              Generate Date: {new Date().toDateString()}
            </div>
          </ConfiguratorLayout>
        );
      }}
    />
  );
};

export default Configurator;

Configurator.propTypes = {
  options: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  productOptionList: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
    .isRequired,
  customPrice: PropTypes.bool,
  configurationReminder: PropTypes.object,
  slug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  hasOffImage: PropTypes.bool.isRequired,
  productImagesCarousel: PropTypes.array.isRequired,
  productFinishes: PropTypes.array.isRequired,
};
